<template>
    <div>
        <span class="text">智慧国防</span>
        <el-carousel trigger="click" :autoplay="true" height="700px" indicator-position="outside" class="Top">
        <el-carousel-item :key="1" >
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case28.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：国家粮食和物资储备局373处</div>
                    <div class="box-text-text2">工程内容：综合布线、安防监控、武警智慧磐石等</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="2">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case29.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省军区</div>
                    <div class="box-text-text2">工程内容：执勤监控平台系统、安防监控系统、机房工程系统</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="3">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case30.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中国人民解放军95829部队</div>
                    <div class="box-text-text2">工程内容：机房工程系统、智慧营区系统、短波通信系统、计算机网络系统</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="4">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case30.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：待接收</div>
                    <div class="box-text-text2">工程内容：待接收</div>
                </div>
            </div>
        </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    //height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    &-img{
        width: 95%;
        //height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        top: 0rem;
        &-img1 {
            margin-top: 1rem;
            width: 100%;
            //height:100%;
        }
    }
    &-text{
        top: 4rem;
        line-height:1.5rem;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 95%;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        &-text1{
            text-align: start;
            width: 100%;
            height: 30px;
            margin-top: 0rem;
            //display: flex;
            position: relative;
            float: inline-start;
        }
        &-text2{
            //display: flex;
            flex-wrap: wrap;
            width: 100%;
            //height: 50px;
            position: relative;
        }
        &-text3{
            display: flex;
            position: relative;
            width: 7%;
        }
        &-text4{
            display: flex;
            position: relative;
            width: 93%;
        }
        &-text5{
            display: flex;
            position: relative;
            width: 100%;
            justify-content: center;
            font-weight: bold;
        }
        &-text6{
            //display: flex;
            position: relative;
            width: 49%;
            top: 1rem;
        }
    }
}
.text{
    margin-top: 2rem;
    line-height:2.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
}
.block{
    width: 100%;
}
 .el-carousel__item {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    border: #475669;
 }
 .el-carousel__item:nth-child(n) {
     background-color: #ffffff;
  }
  .Top{
    margin-top: 1rem;
}
</style>